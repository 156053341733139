import { amgApi } from "@/service/axios"

class ReturnsAssistanceServices {
  async insertTemplate(params) {
    try {
      const data = await amgApi.post(
        "management/settings/insert-template",
        params
      )
      return data
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async getTemplates(params) {
    try {
      const data = await amgApi.post(
        "management/settings/get-templates",
        params
      )
      return data
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async getMessageTypes(){
    try {
      const data = await amgApi.post(
        "management/settings/get-message-types"
      )
      return data
    } catch (error) {
      console.log(error)
      throw error
    }
  }
}

export default new ReturnsAssistanceServices()
