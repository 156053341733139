<template>
  <div>

    <b-col
      class="m-2 p-2"
      md="12"
    >
      <h1 class="text-center">
        <feather-icon
          icon="MessageCircleIcon"
          size="20"
        />
        Returns Assistance Messages
      </h1>
    </b-col>
    <b-container>
      <b-row class="pb-4">
        <b-col md="12">
          <b-card
            title="Messages"
            class="mb-4"
            :class="isDarkSkin ? 'bg-dark' : 'bg-light'"
          >

            <b-row class="my-2">
              <b-col md="12">

                <b-row>
                  <b-col md="6">
                    <b-form-group label="SELECT A RETURNS ASSISTANCE MESSAGE">
                      <b-form-select
                        id="tooltip-target-1"
                        v-model="selected"
                        class="bg-select"
                        :options="options"
                        @change="onSelectChange"
                      />
                    </b-form-group>

                    <b-tooltip
                      target="tooltip-target-1"
                      triggers="hover"
                      variant="info"
                      placement="right"
                    >
                      Select a message to personalize
                    </b-tooltip>

                  </b-col>
                </b-row>

                <h5>LEGEND</h5>
                <b-row>
                  <b-col md="2">
                    <b-input-group
                      size="sm"
                      class="legend"
                    >
                      <b-input-group-prepend
                        class="legend-char"
                        is-text
                      >
                        <b-badge
                          pill
                          variant="info"
                        >
                          @1
                        </b-badge>
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="CLIENT NAME"
                        class="legend"
                        readonly
                      />
                    </b-input-group>
                  </b-col>
                  <b-col md="2">
                    <b-input-group
                      size="sm"
                      class="legend"
                    >
                      <b-input-group-prepend
                        class="legend-char"
                        is-text
                      >
                        <b-badge
                          pill
                          variant="info"
                        >
                          @2
                        </b-badge>
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="SETTLEMENT DATE"
                        class="legend"
                        readonly
                      />
                    </b-input-group>
                  </b-col>
                  <b-col md="2">
                    <b-input-group
                      size="sm"
                      class="legend"
                    >
                      <b-input-group-prepend
                        class="legend-char"
                        is-text
                      >
                        <b-badge
                          pill
                          variant="info"
                        >
                          @3
                        </b-badge>
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="PROGRAMING DATE"
                        class="legend"
                        readonly
                      />
                    </b-input-group>
                  </b-col>
                  <b-col
                    v-if="selected === 6 || selected === 7"
                    md="2"
                  >
                    <b-input-group
                      size="sm"
                      class="legend"
                    >
                      <b-input-group-prepend
                        class="legend-char"
                        is-text
                      >
                        <b-badge
                          pill
                          variant="info"
                        >
                          @4
                        </b-badge>
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="AMOUNT"
                        class="legend"
                        readonly
                      />
                    </b-input-group>
                  </b-col>
                  <b-col
                    v-if="selected === 6 || selected === 7"
                    md="2"
                  >
                    <b-input-group
                      size="sm"
                      class="legend"
                    >
                      <b-input-group-prepend
                        class="legend-char"
                        is-text
                      >
                        <b-badge
                          pill
                          variant="info"
                        >
                          @5
                        </b-badge>
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="CARD NUMBER"
                        class="legend"
                        readonly
                      />
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <b-row class="my-2">

              <!-- Spanish Message -->
              <b-col md="6">
                <b-form-group
                  label="Spanish message (ES)"
                  label-for="textarea1"
                >
                  <b-form-textarea
                    id="textarea1"
                    v-model="noteES"
                    class="ta-bg"
                    placeholder="Enter something..."
                    rows="10"
                    size="lg"
                    :disabled="!editModeES"
                  />
                </b-form-group>
                <b-row>
                  <b-col
                    class="text-left py-2"
                    md="3"
                  >
                    <transition name="fade">
                      <b-button
                        v-if="false"
                        variant="outline-info"
                      >PREVIEW
                      </b-button>
                    </transition>
                    <transition name="fade">
                      <b-button
                        v-if="selected !== null && !editModeES"
                        variant="outline-warning"
                        pill
                        @click="changeEditModeES"
                      >EDIT ES
                        <b-badge
                          pill
                          variant="warning"
                        >
                          <feather-icon
                            icon="EditIcon"
                            size="15"
                          />
                        </b-badge>
                      </b-button>
                    </transition>

                  </b-col>

                  <b-col
                    class="text-right py-2"
                    md="9"
                  >
                    <transition name="fade">
                      <b-button
                        v-if="selected !== null && editModeES"
                        class="mr-1"
                        :class="isDarkSkin ? 'my-dark-btn-primary': 'my-light-btn-primary'"
                        variant="primary"
                        @click="insertTemplate('ES')"
                      >SAVE
                        <feather-icon
                          icon="SaveIcon"
                          size="15"
                        />
                      </b-button>
                    </transition>
                    <transition name="fade">
                      <b-button
                        v-if="selected !== null && editModeES"
                        :class="isDarkSkin ? 'my-dark-btn-danger':'my-light-btn-danger'"
                        variant="danger"
                        @click="cancelEditModeES()"
                      >CANCEL EDIT
                        <feather-icon
                          icon="XIcon"
                          size="15"
                        />
                      </b-button>
                    </transition>
                  </b-col>
                </b-row>
              </b-col>

              <!-- English Message -->
              <b-col md="6">

                <b-form-group
                  label="English message (EN)"
                  label-for="textarea2"
                >
                  <b-form-textarea
                    id="textarea2"
                    v-model="noteEN"
                    class="ta-bg"
                    placeholder="Enter something..."
                    rows="10"
                    size="lg"
                    :disabled="!editModeEN"
                  />
                </b-form-group>
                <b-row>
                  <b-col
                    class="text-left py-2"
                    md="3"
                  >
                    <transition name="fade">
                      <b-button
                        v-if="selected !== null && !editModeEN"
                        variant="outline-warning"
                        pill
                        @click="changeEditModeEN"
                      >EDIT EN
                        <b-badge
                          pill
                          variant="warning"
                        >
                          <feather-icon
                            icon="EditIcon"
                            size="15"
                          />
                        </b-badge>
                      </b-button>
                    </transition>

                  </b-col>

                  <b-col
                    class="text-right py-2"
                    md="9"
                  >
                    <transition name="fade">
                      <b-button
                        v-if="selected !== null && editModeEN"
                        class="mr-1"
                        :class="isDarkSkin ? 'my-dark-btn-primary': 'my-light-btn-primary'"
                        variant="primary"
                        @click="insertTemplate('EN')"
                      >SAVE
                        <feather-icon
                          icon="SaveIcon"
                          size="15"
                        />
                      </b-button>
                    </transition>
                    <transition name="fade">
                      <b-button
                        v-if="selected !== null && editModeEN"
                        :class="isDarkSkin ? 'my-dark-btn-danger':'my-light-btn-danger'"
                        variant="danger"
                        @click="cancelEditModeEN()"
                      >CANCEL EDIT
                        <feather-icon
                          icon="XIcon"
                          size="15"
                        />
                      </b-button>
                    </transition>
                  </b-col>
                </b-row>
              </b-col>

            </b-row>
            <b-col class="text-right">
              <b-button
                id="show-btn"
                variant="outline-primary"
                @click="$bvModal.show('bv-modal-example')"
              >
                <feather-icon
                  icon="HelpCircleIcon"
                  size="20"
                />
              </b-button>
            </b-col>
            <b-card-text>
              Edit messages for assistance and returns management here for better control!
            </b-card-text>

          </b-card>
        </b-col>
      </b-row>
      <b-modal
        id="bv-modal-example"
        hide-footer
      >
        <template #modal-title>
          Recomendations
        </template>
        <div class="d-block text-left">
          <h3>Instrucciones para Personalizar Plantillas de Mensajes:</h3>
          <ol>
            <li>Selecciona una Plantilla:
              <ul>
                <li>Elige la plantilla de mensaje que deseas personalizar.</li>
              </ul>
            </li>
            <li>Identifica los Marcadores de Variables:<ul>
              <li>Observa los marcadores de variables en la plantilla, como @1, @2 o @3. Estos se reemplazarán con información especifica de cada cliente.</li>
            </ul>
            </li>
            <li>Personaliza la Plantilla:<ul>
              <li>Los marcadores de variables son información que se reemplaza para cada usuario al que se envia el mensaje, ver la siguiente descripcion:<ul>
                <li>@1: Nombre completo del cliente.</li>
                <li>@2: Fecha de pago.</li>
                <li>@3: Fecha programada de pago.</li>
                <li>@4: Amount.</li>
                <li>@5: Card number.</li>
              </ul>
              </li>
            </ul>
            </li>
            <li>Guarda el Mensaje:<ul>
              <li>Utiliza el boton “SAVE” para guardar los cambios del mensaje personalizado.</li>
              <li>Verifica que todos los datos estén correctos antes de confirmar.</li>
            </ul>
            </li>
          </ol>

        </div>
      </b-modal>

    </b-container>
  </div>
</template>

<script>
import ReturnsAssistanceService from '@/views/management/views/settings/views/returns_assistance/service/index';
import { mapGetters } from 'vuex';

export default {

  data() {
    return {

      editModeES: false,
      editModeEN: false,

      selected: null,
      options: [],

      noteES: '',
      noteEN: '',
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },

  created() {
    this.getMessageTypes();
  },

  methods: {

    async getMessageTypes() {
      const data = await ReturnsAssistanceService.getMessageTypes();
      this.options = data.data.map(item => ({
        value: item.id,
        text: item.content,
      }));
    },

    async insertTemplate(language) {
      const confirm = await this.showConfirmSwal('IMPORTANT', 'Are you sure you want to perform this action?');
      if (confirm.value) {
        if (!this.noteES || !this.noteEN) {
          // Handle the case where noteES or noteEN is empty
          this.showErrorSwal('Warning', 'Please fill in both note ES and note EN fields.');
          return;
        }

        this.addPreloader();

        try {
          const params = {
            type_message: this.selected,
            languages: language,
            message: language === 'ES' ? this.noteES : this.noteEN,
            user_id: this.currentUser.user_id,
          };

          const data = await ReturnsAssistanceService.insertTemplate(params);

          if (data.status === 200) {
            this.showSuccessSwal(null, data.message);
            this.removePreloader();
            if (language === 'EN') {
              this.cancelEditModeEN();
            } else {
              this.cancelEditModeES();
            }
          }
        } catch (error) {
          console.error('Error inserting template:', error);
          this.removePreloader();
        }
      }
    },

    async onSelectChange() {
      this.cancelEditModeES();
      this.cancelEditModeEN();

      this.addPreloader();

      try {
        const params = {
          type_message: this.selected,
        };

        const data = await ReturnsAssistanceService.getTemplates(params);

        if (data.data.length !== 0) {
          // Encuentra el índice del objeto que contiene el mensaje en español
          const indexES = data.data.findIndex(item => item.languages === 'ES');

          // Encuentra el índice del objeto que contiene el mensaje en inglés
          const indexEN = data.data.findIndex(item => item.languages === 'EN');

          if (indexES !== -1) {
            this.noteES = data.data[indexES].message;
          } else {
            this.noteES = 'CAMBIE ESTE MENSAJE';
          }

          if (indexEN !== -1) {
            this.noteEN = data.data[indexEN].message;
          } else {
            this.noteEN = 'CHANGE THIS MESSAGE';
          }
        } else {
          this.noteES = 'CAMBIE ESTE MENSAJE';
          this.noteEN = 'CHANGE THIS MESSAGE';
        }
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        console.log(error);
      }
    },

    changeEditModeES() {
      this.editModeES = true;
    },

    cancelEditModeES() {
      this.editModeES = false;
    },

    changeEditModeEN() {
      this.editModeEN = true;
    },

    cancelEditModeEN() {
      this.editModeEN = false;
    },

  },
};
</script>
<style scoped>

/* Animación de entrada */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.bg-custom{
  border-radius: 10px;
  /* height: 500px; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.my-bg-white{
  background-color: #ffffff;
}

.bg-dark{
  background-color: #282828 !important;
}

.bg-light{
  background-color: #f7f3ff !important;
}

.legend{
  border:1px solid #a688fa !important ;
  border-radius: 5px;
}

.legend::placeholder{
  color: #a688fa !important;
  font-weight: bold;
}

.legend-char,input-group-text{
  border: none !important;
}
.input-group-text{
  color: #a688fa !important;
  font-weight: bold;
}

.my-dark-btn-primary{
  background: #0090E7 !important;
  border: none;
  color: #121212 !important;
  font-weight: bolder;
}

.my-dark-btn-danger{
  background: #ff3d60 !important;
  border: none;
  color: #121212 !important;
  font-weight: bolder;
}

.ta-bg{

  border: 1px solid #a688fa !important;
  box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.75);
}

.bg-select{

  border: 1px solid #a688fa !important;
  /* border-radius: 2rem !important; */

}

</style>
